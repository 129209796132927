<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="select-area mr-2">
      <b-form-select
        @change="changeArea"
        size="sm"
        v-model="areaVal"
        :options="areaList"
      ></b-form-select>
    </div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.fullName || userData.username }}
          </p>
          <span class="user-status">{{ userData.role }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      areaVal: '',
      areaList: [
        { text: '所有地區', value: '' },
        { text: '香港', value: 'Hong Kong' },
        { text: '澳門', value: 'macau' }
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText
    }
  },
  methods: {
    changeArea() {
      console.log(this.areaVal)
      window.sessionStorage.setItem('area', this.areaVal)
      this.$router.go(0)
    },
    logout() {
      // Remove userData from localStorage
      // // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // // Reset ability
      // this.$ability.update(initialAbility)

      // // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      localStorage.removeItem('userData')
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push('/login')
    }
  },
  created() {
    if (window.sessionStorage.getItem('area')) {
      this.areaVal = window.sessionStorage.getItem('area')
    } else {
      this.areaVal = ''
    }
  }
}
</script>
